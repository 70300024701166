import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Puesto } from 'src/app/models/puesto.model';

@Injectable()
export class PuestoService {

  constructor(
    public http: HttpClient
  ) { }

  obtenerPuestosPorDepartamento(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/puestos`;
    return this.http.get<any>(url,{ params: httpParams });
  }

  crearPuesto(puesto: Puesto) {
    let url = `${URL_SERVICIOS}/api/puestos`;
    return this.http.post<any>(url, puesto);
  }

  actualizar(puesto: Puesto) {
    let url = `${URL_SERVICIOS}/api/puestos/${puesto.idPuesto}`;
    return this.http.put<any>(url, puesto);
  }




  public async visualizarReporte(obj:any): Promise<string> {
    console.log('obj',obj);
    const url = `${URL_SERVICIOS}/api/periodo/${obj.periodo.idPeriodo.toString()}/cuestionario/${obj.cuestionario.idCuestionario.toString()}/contestado`
    console.log(url);
    return url;
  }

  public async downloadReporteExcel(obj:any , tipoReporte:any): Promise<string> {
    console.log('obj',obj);
     const url = `${URL_SERVICIOS}/api/archivo/descargar?idPeriodo=${obj.periodo.idPeriodo.toString()}&idEvaluacion=${obj.cuestionario.idCuestionario.toString()}&tipoReporte=${tipoReporte}`;
         console.log(url);
    return url;
  }



  

}